<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cargues Tramas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Cargues Tramas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-warning btn-md"
                        @click="verPDF()"
                      >
                        <i class="fas fa-question"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_trama_export"
                        v-if="$store.getters.can('tif.tramasPendientes')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"                        
                        class="btn btn-sm alert-default-info py-0"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-link btn-sm"
                        @click="exportarTramaVehiculos()"
                      >
                        Descargar Plantilla
                        <i class="fas fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 table-responsive">
                <table
                  id="solicitudes"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Turno
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.idTurno"
                          placeholder="#Turno"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nCabezote"
                          placeholder="Vehículo"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nEmpresa"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @change="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tramas.data" :key="item.id">
                      <td>
                        <button
                          type="button"
                          class="btn btn-sm btn-default py-0"
                          data-toggle="modal"
                          data-target="#modal-form-detTurno"
                          @click="llenarModalDetTurno(item.tif_turno_id)"
                        >
                          {{ item.tif_turno_id }}
                        </button>
                      </td>
                      <td class="text-nowrap">
                        {{ item.vehiculo.placa }}
                      </td>
                      <td>{{ item.empresa.razon_social }}</td>
                      <td class="text-center">
                        {{ item.fecha_ini }} {{ item.hora_ini }}
                      </td>
                      <td class="text-center">
                        {{ item.fecha_fin }} {{ item.hora_fin }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1
                              ? 'badge-warning'
                              : item.estado == 2
                              ? 'badge-success'
                              : 'badge-danger'
                          "
                          >{{ item.estadoCargueTrama }}</span
                        >
                      </td>
                      <td style="width: 50px" class="text-left">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-excel-tramas"
                            style="cursor: pointer"
                            v-if="item.estado == 1"
                            @click="llenarModalImport(item)"
                          >
                            <i class="fas fa-file-import"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="tramas.total">
                  <p>
                    Mostrando del <b>{{ tramas.from }}</b> al
                    <b>{{ tramas.to }}</b> de un total:
                    <b>{{ tramas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tramas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <TifTurnoDet ref="TifTurnoDet" />
        <TifTramasExport ref="TifTramasExport" />
        <TifCarguesTramasImport ref="TifCarguesTramasImport" />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import TifTurnoDet from "../turnos/TifTurnoDet";
import TifCarguesTramasImport from "../carguesTramas/TifCarguesTramasImport";
import TifTramasExport from "../carguesTramas/TifTramasExport";
/*import vSelect from "vue-select"; */
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "TifCarguesTramasIndex",
  components: {
    Loading,
    pagination,
    TifTurnoDet,
    TifCarguesTramasImport,
    TifTramasExport,
    /*vSelect, */
  },
  data() {
    return {
      cargando: false,
      tramas: {},
      filtros: {},
      vehiculo: null,
      empresa: null,
      listasForms: {
        estados: [],
        cabezotes: [],
        empresas: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      link: "adjuntos/documentos/Tramas/manual.pdf",
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tif/tramasPendientes/index?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.tramas = response.data;
          this.cargando = false;
        });
    },

    mensaje() {
      this.$swal({
        text: "",
        icon: "info",
        html: `<p>En dado caso que no se encuentren informados de como subir el cargue de tramas, por favor dar click en el botón</p>
              <button type="button"class="btn btn-warning btn-md">
              <i class="fas fa-question"></i></button>`,
        confirmButtonText: "Aceptar!",
      });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    exportarTramaVehiculos() {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/tif/tramasPendientes/export",
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error inesperado " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    buscarCabezotes() {
      let me = this;
      var url = "api/admin/vehiculos/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.cabezotes = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    seleccionarTransportadora() {
      if (this.empresa != null) {
        this.filtros.empresa_id = this.empresa.id;
      } else {
        this.filtros.empresa_id = null;
      }
    },

    llenarModalDetTurno(turno) {
      this.$refs.TifTurnoDet.llenar_modal_detTurno(turno);
    },

    llenarModalImport(cargue) {
      this.$refs.TifCarguesTramasImport.llenarModalImport(cargue);
    },

    getEstados() {
      axios.get("/api/lista/106").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    verPDF() {
      let url = "manuales/001-P-MANUAL+CARGUE+TRAMAS.pdf ";
      window.open(this.uri_docs + url, "_blank");
    },
  },

  mounted() {
    this.getIndex();
    this.mensaje();
    this.getEstados();
    this.buscarCabezotes();
    this.buscarTransportadora();
  },
};
</script>
